<template>
  <div id="newProductBuyBox">
    <div
      v-if="!(loadingState)"
      :class="{ 'buy-box--inactive': excludedBrandName || !isActive || inProgress || loading,
                'buy-box--loading': inProgress || loading }"
    >
      <div
        v-if="$auth.loggedIn && !isDefaultCustomer"
        class="availability-pricing"
        :class="{'pdp-box ' : !isEditCart, 'overflow-hidden': !isEditCart}"
        :key="stateCounter"
      >
        <div class="blur-buy-box">
          <div
            v-if="isRequestOnly || isDiscontinuedAndStockZero"
            :class="{'edit-cart' : isEditCart}"
          >
            <HeadLine :headline="headlineRequest" />
          </div>
          <div
            v-else
            :class="{'edit-cart' : isEditCart}"
          >
            <HeadLine :headline="headline" />
          </div>
          <div class="availability-pricing__in-stock">
            <div class="stock-column">
              <div class="content-column">
                <div
                  class="label-column"
                  v-if="hasShowAlternativePlants"
                >
                  <div
                    class="label-text-stockInfo"
                    :class="{'edit-cart' : isEditCart}"
                    v-text="$tv('stockInformation', 'product')"
                  />
                </div>
                <div v-if="hasShowAlternativePlants">
                  <div v-if="availabilities && availabilities.length > 1">
                    <div
                      v-if="nonDisplayOnlyAltPlants.length > 1"
                      :class="{'edit-cart' : isEditCart}"
                      class="sub-info light"
                      v-tv:product="'changeWarehouse'"
                    />
                  </div>
                  <div
                    v-for="(warehouse, index) in availabilities.filter(a => !isDisplayOnlyWareHouse(a.plant))"
                    :key="index + uuid"
                  >
                    <div
                      v-show="!isDisplayOnlyWareHouse(warehouse?.plant)"
                      class="warehouse-row"
                    >
                      <input
                        :type="`${nonDisplayOnlyAltPlants.length > 1 ? 'radio' : 'hidden'}`"
                        :id="uuid + warehouse.plant"
                        :value="warehouse.plant"
                        :checked="chosenWarehouse === warehouse.plant"
                        :name="uuid + 'radioWarehouseChange'"
                        @click="chosenWarehouse = warehouse.plant"
                      >
                      <label :for="uuid + warehouse.plant">
                        <div>
                          <span class="light">{{ plantName(warehouse?.plant) }}</span>
                          <span
                            class="light"
                            v-if="warehouse.plant === product?.defaultPlantCode && nonDisplayOnlyAltPlants.length > 1"
                          >*</span>
                          <span>{{ ": " + caseStock(warehouse.currentStock) }}</span>
                          <div
                            v-if="chosenQuantity > 0 && (!notMultipliableByPackaging || customerSwitchOffRounding)"
                            class="light"
                          >
                            <div
                              v-for="(stockLine, indexx) in stockInfos(warehouse.stockAvailabilities)?.lines"
                              :key="indexx"
                              class="future-line"
                            >
                              <span
                                v-tv:product="{messageKey: 'stockAvailability', messageValues: {qty: stockLine.quantity.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de'), date: $formatDate(stockLine?.date, userDateFormat)}}"
                              />
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div
                    v-show="hasDisplayOnlyWarehouses"
                    class="display-only-warehouse"
                  >
                    <div class="display-only-warehouse__container">
                      {{ $tv({messageKey: isNorthAmerica ? 'displayOnlyNa' : 'displayOnly', messageValues: {qty: displayOnlyStock, unit: $tv(unit, 'packageUnits')} }, 'product') }}
                      <ActionInfoFilledIcon @click="showPopup = true" />
                    </div>
                    <PopupBase
                      :show="showPopup"
                      :size="popupSizeSmall"
                      :level="2"
                      @close-pop-up="showPopup=false"
                    >
                      <h5>{{ $tv("displayOnlyWarehouseTitle", "product") }}</h5>
                      <div class="popup-content">
                        {{ $tv(isNorthAmerica ? 'displayOnlyWarehouseInfoNa' : 'displayOnlyWarehouseInfo','product') }}
                      </div>
                    </PopupBase>
                  </div>
                  <div v-if="!availabilities && !inProgress">
                    <span v-if="product?.defaultPlantCode !== null">{{ plantName(product?.defaultPlantCode) }}</span>
                    <span v-if="product?.defaultPlantCode !== null">*:</span>
                    <span class="light">{{ $tv("noAvailability", "product") }}</span>
                  </div>
                  <div
                    v-if="product?.defaultPlantCode !== null && nonDisplayOnlyAltPlants.length > 1"
                    class="bottom-info light"
                  >
                    <span v-text="'*'" />
                    <span v-tv:product="'defaultWarehouseNotice'" />
                  </div>
                </div>
                <div v-else>
                  <div class="no-gap">
                    <div class="label-column">
                      <div
                        :class="{'edit-cart' : isEditCart}"
                        class="label-text-stock"
                        v-text="$tv('availability', 'categories')"
                      />
                      <div>
                        <span
                          v-if="this.case?.currentStock == null"
                          v-tv:product="'noAvailability'"
                        />
                        <span>
                          {{ caseStock(this.case?.currentStock) }}
                        </span>
                        <span
                          v-show="isDiscontinued && !isDiscontinuedAndStockZero"
                          class="only-remaining-stock"
                          v-tv:product="'onlyRemainingStock'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="availability-pricing__quantity"
            :class="{
              'availability-pricing__quantity-case4': isRequestOnly,
            }"
          >
            <div class="availability-pricing__inputs">
              <div class="availability-pricing__inputs__left">
                <label
                  v-tv:product="'enterQty'"
                  class="quantity-label"
                />
                <NumericInput
                  class="label-input"
                  ref="quantityInput"
                  v-model="chosenQuantity"
                  :spinners="true"
                  @keydownEnter="handleEnter"
                  @input="handleChangedInput()"
                  @change="handleMaxQuantity()"
                  @spin="handleChangedInput(true)"
                  :step="packagingUnit"
                  :placeholder="this.$tv('enterQtyPlaceholder', 'product')"
                  new-style-with-spinner
                  :key="inputCounter"
                  :max="maxQuantity"
                  :min="minQuantity"
                />
                <div
                  class="availability-pricing__packaging"
                  v-if="!(isDiscontinuedAndStockZero || isLimitedAvailabilityAndStockZero || isRequestOnly)"
                >
                  <span v-tv:product="{ messageKey: 'minimumQty', messageValues: { packagingUnit: minimumOrderQuantity.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de') } }" />
                  <span v-tv:product="{ messageKey: 'multipleQty', messageValues: { packagingUnit: packagingUnit.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de') } }" />
                </div>
              </div>
              <div class="availability-pricing__inputs__right">
                <label
                  v-tv:product="(isRequestOnly || isDiscontinuedAndStockZero || isMarkedAsRequest) ? 'expectedDate' : 'availLabel'"
                  class="availability-label"
                />
                <div
                  class="date-picker-wrapper label-input"
                  @click="handleDatePicker"
                >
                  <DatePicker
                    v-model="availabilityDate"
                    name="availabilityDate"
                    :min="minAvailabilityDate"
                    :max="maxAvailabilityDate"
                    @keydownEnter="triggerTab"
                    @change="changeDate"
                    @blur="handleDateBlur"
                    @focus="handleDateFocus"
                    @toggleCalender="handleDatePicker"
                    :show="show"
                    :default-value="defaultDate"
                    class="date-picker-wrapper__date"
                  />
                </div>
                <div
                  class="availability-pricing__packaging"
                  v-if="!(isDiscontinuedAndStockZero || isLimitedAvailabilityAndStockZero || isRequestOnly) && (materialPrice?.minimumOrderLineValue != null && materialPrice?.minimumOrderLineValue > 0)"
                >
                  <span v-tv:cart="'minPositionValue'" />
                  <Price
                    :price="materialPrice?.minimumOrderLineValue"
                    class="total-price-element"
                  />
                </div>
              </div>
            </div>
            <div class="messageBox_Container">
              <div v-if="showErrorMessage && !showLimitedAvailabilityNoStockError && !isRequestOnly">
                <MessageBoxContainer
                  :show-error-message="true"
                >
                  <span v-tv:product="'quantityNotMultipliable'" />
                  <ReactiveButton
                    v-tv:product="'roundUp'"
                    @click="handleRoundUp"
                    class="round-up"
                    :is-primary-button="false"
                  />
                </MessageBoxContainer>
              </div>
              <div v-else-if="showLimitedAvailabilityStockError || showDiscontinuedStockError">
                <MessageBoxContainer
                  :show-error-message="true"
                  class="message-maxstock-case"
                >
                  <span v-tv:product="{ messageKey: 'aboveStockLimitedAvailability', messageValues: { pieces: this.chosenQuantity.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de') } }" />
                  <ReactiveButton
                    @click.prevent="handleReduceRemaining"
                    v-tv:product="{ messageKey: 'reduceQuantity', messageValues: { maxDivisibleStock: maxDivisibleStock?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de') } }"
                    :is-primary-button="false"
                  />
                </MessageBoxContainer>
              </div>
              <div v-else-if="showLimitedAvailabilityNoStockError">
                <MessageBoxContainer
                  :show-error-message="true"
                  class="message-maxstock-case"
                >
                  <span v-tv:product="'noStockLimitedAvailability'" />
                </MessageBoxContainer>
              </div>
              <div v-else-if="showWarningMessage">
                <MessageBoxContainer
                  :show-warning-message="showWarningMessage"
                  class="above-stock"
                >
                  <div class="above-stock__message-container">
                    <div v-tv:product="{ messageKey: 'aboveStock', messageValues: { qty: chosenQuantity.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de') } }" />
                    <div v-tv:product="'orChoose'" />
                  </div>
                </MessageBoxContainer>
              </div>
              <div
                v-else-if="showInfoBox"
                class="info-messages"
              >
                <MessageBoxContainer
                  v-if="showMaxOrderQuantityWarningMessage"
                  :show-warning-message="showMaxOrderQuantityWarningMessage"
                  class="max-qty"
                >
                  <div class="max-qty__message-container">
                    <span v-tv:orderValidation="'OrderCheck_MaxOrderQuantity'" />
                  </div>
                </MessageBoxContainer>
                <MessageBoxContainer
                  :show-success-message="(stockInfos(this.case?.stockAvailabilities))?.lines.length > 0 && stockInfos(this.case?.stockAvailabilities)?.onStock"
                  :show-warning-message="stockInfos(this.case?.stockAvailabilities)?.lines.length > 0 && !stockInfos(this.case?.stockAvailabilities)?.onStock"
                >
                  <ul>
                    <li
                      v-for="(stockAvailability, index) in stockInfos(this.case?.stockAvailabilities)?.lines"
                      :key="index"
                      v-tv:product="{
                        messageKey: 'stockAvailability',
                        messageValues: {
                          qty: stockAvailability?.quantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de'),
                          keyP: this.$tv(this.unit, 'packageUnits'),
                          date: $formatDate(stockAvailability?.date, userDateFormat)
                        },
                      }"
                    />
                  </ul>
                </MessageBoxContainer>
              </div>
            </div>
            <div
              v-if="isRequestOnly && hideExpPrice"
              class="request-price"
            >
              <label v-tv:product="'enterExpPrice'" />
              <input
                type="number"
                min="0"
                class="expPrice"
                step="0.01"
                v-model="expectedPrice"
              >
              <input
                type="text"
                min="0"
                class="expPriceCurrency"
                :placeholder="currencySymbol"
                disabled
              >
              <label v-tv:product="'per'" />
              <input
                type="number"
                min="0"
                class="expPriceQty"
                v-model="expectedPriceQuantity"
              >
              <input
                type="text"
                min="0"
                class="expPriceQtyUnit"
                :placeholder="product?.variant?.unit"
                disabled
              >
            </div>
            <div
              class="availability-pricing__edit-cart"
              v-if="isEditCart"
            >
              <div class="availability-pricing__edit-cart__input-fields">
                <div class="availability-pricing__edit-cart__input-fields__position-note-expected-price">
                  <div>
                    <label for="positionNote">{{ $tv('positionNote', 'product') }}</label>
                    <input-text
                      id="positionNote"
                      v-model="localCartItemValue.positionNote"
                      class="label-input"
                    />
                  </div>

                  <div v-if="isExpectedPriceEnabled">
                    <label for="expectedPrice">{{ $tv('expectedPrice', 'product') }}</label>
                    <NumericInput
                      :format="numberFormat"
                      id="expectedPrice"
                      class="label-input"
                      v-model="localCartItemValue.expectedPricePer100"
                      @input="handleExpectedPriceChange($event)"
                    />
                  </div>
                </div>
                <div class="availability-pricing__edit-cart__input-fields__proforma-certificate">
                  <div
                    v-show="!isMarkedAsRequest && isEndCustomerPriceEnabled"
                  >
                    <label for="proforma">{{ $tv('proforma', 'product') }}</label>
                    <NumericInput
                      id="proforma"
                      v-model="localCartItemValue.customerPricePer100"
                      class="proforma label-input"
                      :class="{'proforma-with-certificate': !isMarkedAsRequest && certificates.length > 1}"
                      :format="numberFormat"
                    />
                  </div>

                  <div
                    v-show="!isMarkedAsRequest && certificates.length > 1"
                  >
                    <label for="certificate">{{ $tv('selectCertificate', 'product') }}</label>
                    <DropDown
                      id="certificate"
                      class="dropdown-certificate label-input"
                      :data-items="certificates"
                      @change="emitCertificate"
                      :value="selectedCertificate"
                      :data-item-key="'value'"
                      :text-field="'text'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="availability-pricing__in-stock price-calc-container"
              v-if="!isStockInfoUser && chosenQuantity > 0 && !isRequestOnly && !(isDiscontinued && chosenQuantity > this.case?.currentStock?.quantity)"
            >
              <div
                class="price-calc-container__items"
              >
                <div>
                  <span v-tv:cart="keyPrice1" />
                  <span v-if="existingPrices">
                    <Price
                      v-if="!loadPrices"
                      :price="price?.pricePerPiece"
                    />
                    <Skeleton
                      v-else
                      class="price-skeleton"
                    />
                  </span>
                  <span v-else>-</span>
                </div>
                <div>
                  <span v-tv:cart="keyPrice100" />
                  <span v-if="existingPrices">
                    <Price
                      v-if="!loadPrices"
                      :price="price?.pricePer100"
                    />
                    <Skeleton
                      v-else
                      class="price-skeleton"
                    />
                  </span>
                  <span v-else>-</span>
                </div>
                <div>
                  <span
                    v-if="existingPrices && certificatePrice > 0"
                    v-text="selectedCertificate.text"
                  />
                  <span v-if="existingPrices && certificatePrice > 0">
                    <Price
                      class="price-calc-container__items__certificate"
                      v-if="!loadPrices"
                      :price="certificatePrice"
                    />
                    <Skeleton
                      v-else
                      class="price-skeleton"
                    />
                  </span>
                </div>
                <div>
                  <span
                    v-tv:cart="'price'"
                    class="total-price-label"
                  />
                  <div
                    v-if="isMvpActive && (!hasExpectedPrice || !isEditCart)"
                    class="minPosVal"
                  >
                    <Price
                      :price="materialPrice?.minimumOrderLineValue"
                      class="total-price-element"
                    />
                    <span
                      v-tv:cart="'minPositionValue'"
                      class="mpv-label"
                    />
                  </div>
                  <div v-else>
                    <Price
                      v-if="!loadPrices"
                      :price="selectedCertificate !== null ? price?.priceForRequestedQuantity + certificatePrice : price?.priceForRequestedQuantity"
                      class="total-price-element"
                    />
                    <Skeleton
                      v-else
                      class="price-skeleton-big"
                    />
                  </div>
                </div>
              </div>
              <div
                class="message-container"
                v-if="!showErrorMessage && !showLimitedAvailabilityStockError && !showLimitedAvailabilityNoStockError && !(isEditCart && isMarkedAsRequest)"
              >
                <div
                  class="exceptional-case"
                  v-if="(result?.priceForRequestedQuantity === null || !existingPrices) && isQuoterUser && !loadPrices"
                  v-tv:product="'noPriceMessageQuoteUser'"
                />
                <div
                  class="exceptional-case"
                  v-if="(result?.priceForRequestedQuantity === null || !existingPrices) && displayBuyButton && !isLimitedAvailability && !isQuoterUser && !isInfoUser && !loadPrices"
                  v-tv:product="'noPriceMessage'"
                />
                <div
                  class="exceptional-case"
                  v-if="(result?.priceForRequestedQuantity === null || !existingPrices) && displayBuyButton && isLimitedAvailability && !isQuoterUser && !isInfoUser && !loadPrices"
                  v-tv:product="'noPriceMessageLimitedAvailability'"
                />
                <div
                  class="message-mpv-case"
                  v-if="messageMpvCase && result?.priceForRequestedQuantity !== null && displayBuyButton && !loadPrices"
                  v-tv:product="{ messageKey: 'mpvMessage', messageValues: { qty: this.price?.SuggestedQuantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')} }"
                />
              </div>
            </div>
            <div
              v-show="!(isInfoUser || isStockInfoUser)"
              class="btn-container"
            >
              <ReactiveButton
                v-show="!isLimitedAvailability && chosenQuantity !== 0"
                :is-primary-button="isQuoterUser || (!displayBuyButton && isDiscontinued) || isRequestOnly"
                @click.prevent="handleRequestButton"
                v-tv:product="'requestQuote'"
                :is-loading="addInProgress"
                :class="{ 'btn-as-link': !isRequestOnly && displayBuyButton && !isQuoterUser, 'edit-cart' : isEditCart}"
              />
              <ReactiveButton
                :disabled="(notMultipliableByPackaging && chosenQuantity !== null && chosenQuantity !== '' && !customerSwitchOffRounding) || chosenQuantity === 0 || showLimitedAvailabilityStockError || showLimitedAvailabilityNoStockError || showDiscontinuedStockError"
                v-if="!isRequestOnly && displayBuyButton && !isQuoterUser"
                @click.prevent="handleBuyButton"
                v-tv:product="'buy'"
                :is-loading="addInProgress"
                :class="{'edit-cart' : isEditCart}"
              />
              <ReactiveButton
                :class="{'edit-cart' : !isEditCart}"
                @click.prevent="saveUpdatedCartItem()"
                v-tv:product="'update'"
              />
            </div>
          </div>
          <PriceScale
            v-if="!isRequestOnly && !isDiscontinuedAndStockZero && !isStockInfoUser && priceScales.length > 0 && !isEditCart"
            :rows="priceScales"
            :is-loading="priceScalesLoading"
            :show-recommended-price="isRecommendedPriceVisible"
            :unit="unit"
          />
        </div>
        <div class="buy-box-mask">
          <div class="buy-box-mask__layer" />
          <span
            v-if="!isBuyable && !excludedBrandName"
            v-tv:product="'productNotBuyable'"
            class="buy-box-mask__text"
          />
          <span
            v-if="excludedBrandName"
            class="buy-box-mask__text"
            @click="handleClick"
            v-html="
              this.$tv('brandExclusionText' + excludedBrandName + '1', 'product') +
                ' <u id=disSearch>' +
                this.$tv('brandExclusionText' + excludedBrandName + 'Link', 'product') +
                '</u> ' +
                this.$tv('brandExclusionText' + excludedBrandName + '2', 'product')
            "
          />
        </div>
      </div>
      <div
        v-if="!$auth.loggedIn || ($auth.loggedIn && isDefaultCustomer)"
      >
        <div v-if="showPartnerHub">
          <PartnerHub
            :partners="partners"
            :product="product"
          />
        </div>
        <ProductBuyBoxAnonymous
          class="pdp-box"
          :is-default-customer="isDefaultCustomer"
          :product="product"
        />
      </div>
    </div>
    <div v-else>
      <availability-and-pricing-skeleton
        :show-price-scales="!isEditCart"
        class="pdp-box"
      />
    </div>
  </div>
</template>

<script>
import HeadLine from './HeadLine.vue'
import PriceScale from './PriceScale.vue'
import Price from '~/components/PriceF'
import DatePicker from '~/components/input-components/DatePicker'
import ProductBuyBoxAnonymous from '~/components/product-detail/ProductBuyBoxAnonymous.vue'
import MessageBoxContainer from './MessageBoxContainer.vue'
import ActionInfoFilledIcon from '~/assets/icons/action_info_filled.svg?component'
import {calculatePriceCalculation} from '~/assets/js/utils/price_helper'
import {
  CUSTOMER_SETTING_KEYS,
  PRODUCT_UNITS,
  PLANT,
  LIMITED_AVAILABILITY_STATUS,
  DISCONTINUED_STATUS,
  REQUEST_ONLY_STATUS,
  MIN_MAX_VALUES,
  REGION_KEYS
} from '~/assets/js/constants'
import {getSettingByKey} from '~/assets/js/settings'
import {getCurrencySymbol} from '~/assets/js/utils/price_logic'
import {CONFIGURATION_KEYS, PROPERTY_SCOPE_NAMES} from '~/assets/js/constants.js'

import {storeToRefs} from 'pinia'
import {useUserStore} from '@/store/user'
import {useCustomerSettingsStore} from '@/store/customer-settings'
import {useCartStore} from '@/store/cart'
import {usePandaService} from '@/composables/panda_service'
import {usePartnerService} from '@/composables/partner_service'
import {usePlantStore} from '@/store/plants'
import {useConfigurationDataStore} from '@/store/configuration-data'

import defaultCustomerMixin from '~/mixins/default_customer'
import NumericInput from '~/components/input-components/NumericInput.vue'
import {getNextDate, getMaxDate} from '~/assets/js/utils'
import AvailabilityAndPricingSkeleton from '../../skeletons/AvailabilityAndPricingSkeleton.vue'
import {Skeleton} from '@progress/kendo-vue-indicators'
import PartnerHub from '~/components/product-detail/bb_components/PartnerHub.vue'
import {POPUP} from '~/assets/js/constants'
import InputText from '../../input-components/InputText.vue'
import DropDown from '@/components/input-components/DropDown.vue'

export default {
  components: {
    PartnerHub,
    HeadLine,
    Price,
    PriceScale,
    DatePicker,
    ProductBuyBoxAnonymous,
    MessageBoxContainer,
    NumericInput,
    AvailabilityAndPricingSkeleton,
    Skeleton,
    ActionInfoFilledIcon,
    InputText,
    DropDown
  },
  mixins: [defaultCustomerMixin],
  props: {
    product: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    inProgressProp: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    addInProgress: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isEditCart: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    cartItemValue: {
      type: Object,
      default() {
        return null
      }
    }
  },
  setup() {
    const {currentCustomerSettings} = storeToRefs(useCustomerSettingsStore())
    const {metainformation, availableCertificates} = storeToRefs(useCartStore())
    const {getPriceScales, getMaterialPrices, getAvailabilities, getAvailabilitiesBulk} = usePandaService()
    const {excludedBrands, settingValues, loadedCustomerUuid, newCustomer} = storeToRefs(useUserStore())
    const {configurations, newSearch} = storeToRefs(useConfigurationDataStore())
    const {deliveryAddressUuid} = storeToRefs(useCartStore())
    const {allPlants} = storeToRefs(usePlantStore())
    const {getProductBySku, getProductBySkuNew} = useProductService()
    const {getPartners} = usePartnerService()
    const cartStore = useCartStore()
    const {updateItem} = cartStore

    return {
      currentCustomerSettings,
      cartInfos: metainformation,
      deliveryAddressUuid,
      getPriceScales,
      getMaterialPrices,
      getAvailabilities,
      excludedBrands,
      configurations,
      fetchedWarehouses: allPlants,
      getAvailabilitiesBulk,
      getProductBySku,
      getProductBySkuNew,
      settingValues,
      loadedCustomerUuid,
      newCustomer,
      getPartners,
      newSearch,
      availableCertificates,
      updateItem
    }
  },
  data() {
    return {
      headline: this.$tv('availabilityPricing', 'product'),
      headlineRequest: this.$tv('requestQuote', 'product'),
      materialPrice: {},
      chosenQuantity: null,
      availabilityDate: getNextDate(),
      minAvailabilityDate: getNextDate(),
      maxAvailabilityDate: getMaxDate(),
      maxQuantity: MIN_MAX_VALUES.Quantity.Max,
      minQuantity: 0,
      show: false,
      defaultDate: getNextDate(),
      blurTimeout: undefined,
      plant: '',
      currentStock: undefined,
      result: null,
      maxDivisibleStock: null,
      messageMpvCase: false,
      expectedPrice: null,
      expectedPriceQuantity: null,
      hideExpPrice: false,
      priceScales: [],
      priceScalesLoading: false,
      inProgress: false,
      chosenWarehouse: this.product?.defaultPlantCode,
      availabilities: [],
      stateCounter: 0,
      inputCounter: 0,
      priceTimer: null,
      loadPrices: false,
      hasSurcharges: false,
      loadingPartners: false,
      partners: null,
      showPopup: false,
      popupSizeSmall: POPUP.Size.small,
      selectedCertificateUuid: this.cartItemValue?.certificateUuid,
      localCartItemValue: {...this.cartItemValue}
    }
  },
  mounted() {
    this.updateValues()
    if (this.isActive && this.product) {
      this.loadData()
    }
    if (this.shouldLoadPartners) {
      this.loadPartners()
    }
  },
  methods: {
    handleExpectedPriceChange($event) {
      // eslint-disable-next-line eqeqeq
      if ($event == null) {
        this.loadPrice(this.chosenQuantity)
      } else {
        this.calculatePrice()
      }
    },
    updateValues() {
      if (this.isEditCart) {
        if (this.cartItemValue) {
          this.chosenQuantity = this.cartItemValue.quantity
          if (this.cartItemValue.availabilityDate) {
            this.availabilityDate = new Date(this.cartItemValue.availabilityDate)
          }
          this.selectedCertificateUuid = this.cartItemValue.certificateUuid
        }
        if (this.cartItemValue?.alternativePlantUuid) {
          this.chosenWarehouse = this.fetchedWarehouses?.find((w) => w?.alternativePlantGuid === this.cartItemValue.alternativePlantUuid)?.alternativePlant
        } else {
          this.chosenWarehouse = this.product?.defaultPlantCode
        }
      } else {
        this.chosenQuantity = null
        this.availabilityDate = getNextDate()
        this.selectedCertificateUuid = null
        this.chosenWarehouse = this.product?.defaultPlantCode
      }
    },
    async saveUpdatedCartItem() {
      const cartItem = this.localCartItemValue
      const oldCartItem = this.cartItemValue
      cartItem.quantity = this.chosenQuantity
      const dateChange = new Date(this.availabilityDate).setHours(0, 0, 0, 0) !== new Date(this.cartItemValue.availabilityDate).setHours(0, 0, 0, 0)
      cartItem.availabilityDate = this.availabilityDate
      cartItem.certificateUuid = this.selectedCertificateUuid
      cartItem.alternativePlantUuid = this.alternativePlantUuid
      cartItem.hasQuantityChanged = oldCartItem.quantity !== this.chosenQuantity
      await this.updateItem({
        cartItem,
        oldCartItem,
        region: this.$globalization.getRegion(),
        locale: this.$i18n.locale
      })
      if (dateChange || cartItem.alternativePlantUuid !== oldCartItem.alternativePlantUuid || cartItem.quantity !== oldCartItem.quantity) {
        this.$emit('on-availability-date-change')
      }
      this.$emit('close-pop-up')
    },
    checkForChanges() {
      const dateChange = new Date(this.availabilityDate).setHours(0, 0, 0, 0) !== new Date(this.cartItemValue.availabilityDate).setHours(0, 0, 0, 0)
      // eslint-disable-next-line eqeqeq
      const certificateChange = this.selectedCertificateUuid != this.cartItemValue.certificateUuid
      const plantChanged = this.alternativePlantUuid !== this.cartItemValue.alternativePlantUuid
      const hasChanges = this.localCartItemValue.positionNote !== this.cartItemValue.positionNote && !(this.localCartItemValue.positionNote === '' && this.cartItemValue.positionNote === null)
        || this.localCartItemValue.expectedPricePer100 !== this.cartItemValue.expectedPricePer100
        || this.localCartItemValue.customerPricePer100 !== this.cartItemValue.customerPricePer100
        || certificateChange
        || this.chosenQuantity !== this.cartItemValue.quantity
        || dateChange
        || plantChanged
      return hasChanges
    },
    async loadPartners() {
      this.loadingPartners = true
      this.partners = await this.getPartners(this.product.sku)
      this.loadingPartners = false
    },
    handleClick(e) {
      let clickedElId = e.target.id
      if (clickedElId === 'disSearch') {
        navigateTo(this.localePath('/distributor-search'))
      }
    },
    async updateData() {
      this.chosenWarehouse = this.product?.defaultPlantCode
    },
    async loadData() {
      if (this.chosenWarehouse === null) {
        this.chosenWarehouse = this.product?.defaultPlantCode
      }
      this.inProgress = true
      let promises = []
      if (this.newCustomer !== null || this.product?.salesAreas[0]?.viewable === false) {
        this.inProgress = false
        this.stateCounter++
        return
      }
      promises.push(this.isEditCart ? this.loadPrice(this.chosenQuantity) : this.loadPrice())
      promises.push(this.loadAvailabilities())
      promises.push(this.loadPriceScales())
      await Promise.all(promises)
      this.calculatePrice()
      this.inProgress = false
      this.stateCounter++
    },
    changeDate(event) {
      const newDate = new Date(event.target.value)
      this.availabilityDate = new Date(event.target.value)
      getNextDate(this.availabilityDate)
      let format = (this.settingValues && this.settingValues.User && this.settingValues.User.DateFormat) || 'MM/DD/YYYY HH:mm:ss'
      const dateFormatString = format.substring(0, 10).toUpperCase()
      if (newDate < this.availabilityDate) {
        this.$toast.warning(this.$tv({messageKey: 'product.adjustedDate', messageValues: {date: this.$formatDate(this.availabilityDate, dateFormatString)}}))
      }
      if (this.availabilityDate) {
        this.show = !this.show
      }
    },
    handleDatePicker() {
      this.show = !this.show
    },
    handleDateBlur() {
      this.blurTimeout = setTimeout(() => {
        this.show = false
      }, 50)
    },
    handleDateFocus() {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout)
      }
    },
    triggerTab() {
      const focusableElements = 'input, [tabindex]:not([tabindex="-1"])'
      const elements = Array.from(document.querySelectorAll(focusableElements))

      const currentIndex = elements.indexOf(document.activeElement)

      const nextElement = elements[currentIndex + 1] || elements[0]
      nextElement.focus()
    },
    handleEnter() {
      const minimumOrderQuantity = this.getMinimumOrderQuantity()
      let formattedQuantity = this.chosenQuantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
      if (this.showErrorMessage) {
        if (this.chosenQuantity < minimumOrderQuantity && !this.customerSwitchOffRounding) {
          this.chosenQuantity = minimumOrderQuantity
        }
        this.chosenQuantity = Math.min(Math.ceil(this.chosenQuantity / this.packagingUnit) * this.packagingUnit, Math.floor(MIN_MAX_VALUES.Quantity.Max / this.packagingUnit) * this.packagingUnit)
        formattedQuantity = this.chosenQuantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
        this.$toast.warning(this.$tv({messageKey: 'product.adjustedQuantity', messageValues: {quantity: formattedQuantity, unit: this.$tv(this.unit, 'packageUnits')}}))
        this.$refs['quantityInput'].blur()
        return
      }

      if (!this.isRequestOnly && this.displayBuyButton && !this.isQuoterUser) {
        if ((this.isDiscontinued || this.isLimitedAvailability) && (this.chosenQuantity > this.case?.currentStock?.quantity)) {
          if (this.showLimitedAvailabilityStockError || this.showDiscontinuedStockError) {
            this.handleReduceRemaining()
            formattedQuantity = this.chosenQuantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
            this.$toast.warning(this.$tv({messageKey: 'product.adjustedQuantity', messageValues: {quantity: formattedQuantity, unit: this.$tv(this.unit, 'packageUnits')}}))
            this.$refs['quantityInput'].blur()
            return
          } else {
            this.$toast.error(this.$tv('toastFailedStock', 'product'))
            return
          }
        }
      }
      this.triggerTab()
    },
    handleBuyButton() {
      if (parseFloat(this.chosenQuantity) > 0 && parseFloat(this.chosenQuantity) < this.product?.packagingUnit && !this.customerSwitchOffRounding) {
        return
      }

      let warehouse = null
      if (this.chosenWarehouse !== this.product?.defaultPlantCode) {
        warehouse = this.fetchedWarehouses.find((w) => w?.plant === this.product?.defaultPlantCode && w.alternativePlant === this.chosenWarehouse)
      }
      this.$emit('addToCart', {quantity: this.chosenQuantity, availabilityDate: this.availabilityDate, warehouse: warehouse, newpdp: true})
    },
    handleRequestButton() {
      if (this.isLimitedAvailability) {
        return
      }
      let warehouse = null
      if (this.chosenWarehouse !== this.product?.defaultPlantCode) {
        warehouse = this.fetchedWarehouses.find((w) => w?.plant === this.product?.defaultPlantCode && w.alternativePlant === this.chosenWarehouse)
      }
      this.$emit('addToCart', {markedAsRequest: true, quantity: this.chosenQuantity, availabilityDate: this.availabilityDate, warehouse: warehouse, newpdp: true})
    },
    clearForm() {
      this.availabilityDate = this.defaultDate
      this.chosenQuantity = null
    },
    expectedOrderDate(date) {
      //no NUXT3/VUE3 TODO use custom dateFormat
      return date?.slice(0, 10).split('-').reverse().join('/')
    },
    getMinimumOrderQuantity() {
      return Math.ceil(this.minimumOrderQuantity / this.packagingUnit) * this.packagingUnit
    },
    handleMaxQuantity() {
      if (this.chosenQuantity >= MIN_MAX_VALUES.Quantity.Max) {
        this.chosenQuantity = Math.floor(MIN_MAX_VALUES.Quantity.Max / this.product?.packagingUnit) * this.product?.packagingUnit
        this.$refs['quantityInput'].blur()
        this.$nextTick(() => {
          this.$refs['quantityInput'].focus()
        })
      }
    },
    async handleChangedInput(spin = false) {
      // eslint-disable-next-line eqeqeq
      if (this.chosenQuantity != null) {
        if ((this.chosenQuantity < this.minimumOrderQuantity
              || this.chosenQuantity < this.packagingUnit
              || this.notMultipliableByPackaging
              || this.chosenQuantity < Math.ceil(this.minimumOrderQuantity / this.packagingUnit) * this.packagingUnit)
            && spin
            && !this.customerSwitchOffRounding) {
          this.chosenQuantity = Math.max(this.getMinimumOrderQuantity(), Math.min(Math.floor(this.chosenQuantity / this.packagingUnit) * this.packagingUnit, Math.floor(MIN_MAX_VALUES.Quantity.Max / this.product?.packagingUnit) * this.product?.packagingUnit))
          this.$refs['quantityInput'].blur()
        }
        if (this.hasSurcharges && !this.hasExpectedPrice) {
          this.loadPrice(this.chosenQuantity)
        } else {
          this.calculatePrice()
        }
      }
    },
    calculatePrice() {
      setTimeout(async () => {
        this.result = calculatePriceCalculation(
          this.chosenQuantity,
          {...this.product, materialPrice: {...this.materialPrice, minimumOrderLineValue: this.materialPrice?.minimumOrderLineValue}},
          this.priceScales, this.hasSurcharges, this.localCartItemValue?.expectedPricePer100
        )
        this.loadPrices = false
        if (this.chosenQuantity > this.case?.currentStock?.quantity || this.case?.currentStock?.quantity === 0) {
          this.maxDivisibleStock = this.customerSwitchOffRounding
            ? this.case?.currentStock?.quantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
            : Math.floor(this.case?.currentStock?.quantity / this.product?.packagingUnit) * this.product?.packagingUnit
        }
        if (this.price?.priceForRequestedQuantity + this.certificatePrice <= this.materialPrice?.minimumOrderLineValue && this.price?.SuggestedQuantity > this.chosenQuantity) {
          this.messageMpvCase = true
        } else {
          this.messageMpvCase = false
        }
      }, 200)
    },
    handleReduceRemaining() {
      this.chosenQuantity = this.maxDivisibleStock
      this.handleChangedInput()
    },
    getStockInfo(requestedQuantity, scheduleLine) {
      return {
        quantity: requestedQuantity <= scheduleLine?.quantity ? requestedQuantity : scheduleLine?.quantity,
        date: scheduleLine?.date,
        finished: requestedQuantity <= scheduleLine?.quantity
      }
    },
    async loadPrice(quantity = 1) {
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE)) {
        this.loadPrices = true
        if (this.priceTimer) {
          clearTimeout(this.priceTimer)
        }
        this.priceTimer = setTimeout(async () => {
          if (quantity !== 1) {
            this.$sentry?.captureMessage(`PDP reload prices customer: ${this.loadedCustomerUuid} sku: ${this.product?.sku} qty: ${quantity}`, {
              level: 'warning'
            })
          }
          let result = await this.getMaterialPrices(this.product?.sku, quantity, true)
          if (result?.quantity === quantity) {
            this.materialPrice = result
          }
          this.calculatePrice()
        }, 200)
      }
    },
    async loadAvailabilities() {
      if (!this.product) {
        return
      }
      this.plant = {...PLANT.DEFAULT_PLANT_OBJECT, plant: this.product?.defaultPlantCode}
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY) && !this.hasShowAlternativePlants) {
        let availability = await this.getAvailabilities(this.product?.sku, MIN_MAX_VALUES.Quantity.Max, this.availabilityDate, this.plant?.plant, true)
        this.availabilities = [availability]
      }

      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY) && this.hasShowAlternativePlants) {
        let filter = []
        const today = new Date()
        today.setDate(today.getDate())
        today.setHours(0, 0, 0, 0)

        const basicFilterObject = {
          sku: this.product?.sku,
          quantity: MIN_MAX_VALUES.Quantity.Max,
          availabilityDate: today.toISOString()
        }
        let warehouses = this.fetchedWarehouses.filter((p) => p?.plant === this.product?.defaultPlantCode)

        if (warehouses.length === 0) {
          warehouses = [this.plant]
        }

        await this.setExistingPlants(warehouses)
        warehouses = warehouses.filter((w) => w.existingForProduct)
        // eslint-disable-next-line vue/no-mutating-props
        this.product._warehouses = warehouses?.map((w) => ({...w, availabilitiesLoading: true})) ?? []

        warehouses.forEach((warehouse) => {
          const filterObject = {...basicFilterObject, requestId: warehouse?.alternativePlantGuid}
          const plant = warehouse && !warehouse.isDefault ? warehouse.alternativePlant : null
          if (plant) {
            filterObject.plant = plant
          } else if (warehouse && warehouse.isDefault) {
            this.defaultPlant = warehouse?.plant
          }
          filter.push(filterObject)
        })
        const availabilities = await this.getAvailabilitiesBulk(filter)
        this.availabilities = availabilities
      }

      if (this.isLimitedAvailability) {
        this.availabilities?.forEach((a) => {
          if (a?.currentStock && a?.currentStock?.quantity < this.packagingUnit) {
            a.currentStock.quantity = 0
          }
        })
      }

      if (this.availabilities && this.availabilities?.length > 1 && this.availabilities?.some((a) => a.requestId === 'default-plant' && a.currentStock?.quantity === 0)
      && this.availabilities?.some((a) => a.requestId !== 'default-plant' && a.currentStock?.quantity > 0)) {
        const defaultPlant = this.availabilities.find((a) => a.requestId === 'default-plant')
        const alternativePlant = this.availabilities.find((a) => a.requestId !== 'default-plant' && a.currentStock?.quantity > 0)
        this.$gtm.trackEvent({
          event: 'atp_defwh_null',
          category: 'atp',
          action: 'load_availabilities',
          ecommerce: {
            'fst_itemno': this.product?.sku,
            'default_warehouse_code': defaultPlant?.plant,
            'first_alternative_warehouse_code': alternativePlant?.plant,
            'first_alternative_warehouse_stock': alternativePlant?.currentStock?.quantity}
        })
      }
    },
    async loadPriceScales() {
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE)) {
        this.priceScalesLoading = true
        await this.$nextTick()
        this.priceScales = []
        if (this.product && this.isActive) {
          let result = await this.getPriceScales(this.product?.sku)
          this.priceScales = result.priceScales
          this.hasSurcharges = result.hasSurcharges
        }
        this.priceScalesLoading = false
        this.$emit('price-scales-loaded', this.priceScales)
      }
    },
    async setExistingPlants(warehouses) {
      const plantProductPromises = []
      for (let w of warehouses) {
        if (w.alternativePlant !== 'default-plant') {
          plantProductPromises.push(this.newSearch ? this.getProductBySkuNew(
            this.product?.sku,
            this.$globalization.getRegion(),
            this.$i18n.locale,
            false,
            w.alternativePlant,
            PROPERTY_SCOPE_NAMES.PLANTS_ONLY_NEW
          ).then((product) => {
            let plant = product.plants.find((p) => p.key === w.alternativePlant.toString())
            w.existingForProduct = typeof plant !== 'undefined'
          }) : this.getProductBySku(
            this.product?.sku,
            this.$globalization.getRegion(),
            this.$i18n.locale,
            false,
            w.alternativePlant,
            PROPERTY_SCOPE_NAMES.PLANTS_ONLY
          ).then((product) => {
            let plant = product.plants.find((p) => p.key === w.alternativePlant.toString())
            w.existingForProduct = typeof plant !== 'undefined'
          }))
        } else {
          w.existingForProduct = true
        }
      }
      if (plantProductPromises.length > 0) {
        await Promise.all(plantProductPromises)
      }
    },
    handleRoundUp() {
      this.chosenQuantity = Math.max(Math.ceil(this.chosenQuantity / this.product?.packagingUnit) * this.product?.packagingUnit, this.getMinimumOrderQuantity())
      if (this.chosenQuantity > MIN_MAX_VALUES.Quantity.Max) {
        this.chosenQuantity = Math.floor(MIN_MAX_VALUES.Quantity.Max / this.product?.packagingUnit) * this.product?.packagingUnit
      }
      this.handleChangedInput()
    },
    plantName(plantCode) {
      return this.$tv(plantCode, PLANT.PLANT_TRANSLATION_KEY)
    },
    caseStock(currentStock) {
      const formattedQuantity = currentStock?.quantity?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
      if (this.isRequestOnly && !this.isDiscontinued) {
        return this.$tv('infoRequestOnly', 'product')
      } else if (this.isDiscontinued && currentStock?.quantity === 0) {
        return this.productSuccessorSku ? this.$tv({messageKey: 'quantityAndUnit', messageValues: {quantity: currentStock?.quantity, unit: this.$tv(this.unit, 'packageUnits')}})
        + `${'\xa0'.repeat(3)}` + this.$tv('onlyRequestCheckSuccessor', 'product')
          : this.$tv({messageKey: 'quantityAndUnit', messageValues: {quantity: formattedQuantity, unit: this.$tv(this.unit, 'packageUnits')}})
        + `${'\xa0'.repeat(3)}` + this.$tv('onlyRequestNoSuccesor', 'product')
      }
      return this.case
        ? (currentStock === undefined
          ? this.$tv('infoReorder', 'product')
          : this.$tv({messageKey: 'quantityAndUnit', messageValues: {quantity: formattedQuantity, unit: this.$tv(this.unit, 'packageUnits')}}))
        : ''
    },
    futureLines(stockAvailabilities) {
      return stockAvailabilities
    },
    prevCount(stockAvailabilities) {
      if (stockAvailabilities.length === 0) {
        return 0
      }
      return stockAvailabilities
        .slice(0, -1)
        .map((l) => l.quantity)
        .reduce((a, b) => a + b)
    },
    estimateMessageType(stock) {
      let classes = []
      if (stock?.lines.length === 1 && stock?.onStock) {
        classes = ['success-message']
      } else if (stock?.lines.length > 1 && !stock?.onStock) {
        classes = ['warning-message']
      }
      return classes
    },
    stockInfos(stockAvailabilities) {
      // eslint-disable-next-line eqeqeq
      if (stockAvailabilities == null || this.chosenQuantity === null || this.chosenQuantity === '') {
        return {onStock: false, lines: []}
      }
      let infos = {onStock: false, lines: []}
      let scheduleLines = stockAvailabilities.map((line) => ({date: new Date(line.date).setHours(0, 0, 0, 0), quantity: line.quantity}))
      let remainingQuantity = this.chosenQuantity
      let scheduleLineTillAvailDate = {date: 0, quantity: 0, finished: false}
      let availValue = new Date(this.availabilityDate).setHours(0, 0, 0, 0)
      let stockLines = scheduleLines.filter((line) => line.date <= availValue)
      let futureLines = scheduleLines.filter((line) => line.date > availValue)

      stockLines.forEach((scheduleLine) => {
        scheduleLineTillAvailDate.quantity += scheduleLine.quantity
        scheduleLineTillAvailDate.date = new Date(this.availabilityDate).setHours(0, 0, 0, 0)
      })

      infos.lines.push(this.getStockInfo(remainingQuantity, scheduleLineTillAvailDate))
      remainingQuantity -= scheduleLineTillAvailDate?.quantity

      if (remainingQuantity <= 0) {
        infos.onStock = true
        return infos
      }

      if (this.isDiscontinued) {
        infos.onStock = false
        return infos
      }

      futureLines.forEach((scheduleLine) => {
        let newLine = this.getStockInfo(remainingQuantity, scheduleLine)
        remainingQuantity -= newLine.quantity
        if (newLine.quantity > 0) {
          infos.lines.push(newLine)
        }
      })

      return infos
    },
    isDisplayOnlyWareHouse: function(plant) {
      let warehouse = this.fetchedWarehouses?.find((w) => w?.plant === this.product?.defaultPlantCode && w.alternativePlant === plant)
      return warehouse?.displayOnly
    },
    emitCertificate(value) {
      this.selectedCertificateUuid = value.value.value
      this.calculatePrice()
    }
  },
  expose: ['checkForChanges', 'saveUpdatedCartItem', '$t', '$te', '$', 'updateData'],
  computed: {
    isNorthAmerica() {
      return this.$globalization.__currentRegion === REGION_KEYS.NA
    },
    hasDisplayOnlyWarehouses() {
      return this.availabilities?.some((w) => this.isDisplayOnlyWareHouse(w?.plant))
    },
    displayOnlyStock() {
      let displayOnlyAvailabilities = this.availabilities?.filter((w) => this.isDisplayOnlyWareHouse(w?.plant))
      let stock = 0
      displayOnlyAvailabilities?.forEach((a) => {
        stock += a?.currentStock?.quantity
      })
      return stock
    },
    loadingState() {
      return this.loading || this.inProgress || this.loadingPartners
    },
    alternativePlantUuid() {
      if (this.chosenWarehouse === this.product?.defaultPlantCode) {
        return null
      }
      return this.fetchedWarehouses?.find((w) => w?.plant === this.product?.defaultPlantCode && w.alternativePlant === this.chosenWarehouse)?.alternativePlantGuid
    },
    selectedCertificate() {
      return this.certificates?.find( c => c.value === this.selectedCertificateUuid) ?? null
    },
    certificatePrice() {
      const certificate = this.availableCertificates?.find(c => c.certificateUuid === this.selectedCertificateUuid)
      return certificate?.price ?? 0
    },
    showPartnerHub() {
      return this.shouldLoadPartners && this.partners?.length > 0
    },
    shouldLoadPartners() {
      return !this.$auth.loggedIn && this.product && this.$globalization.__currentRegion === REGION_KEYS.NA
    },
    productSuccessorSku() {
      return this.product?.salesAreas?.length > 0
        ? this.product.salesAreas[0].successorSku : null
    },
    notMultipliableByPackaging() {
      return (this.product?.packagingUnit > this.chosenQuantity || this.chosenQuantity < this.minimumOrderQuantity || Math.round(((this.chosenQuantity * 100000) % (this.product?.packagingUnit * 100000)) / 100000) !== 0 || this.chosenQuantity === 0 || this.chosenQuantity < 0) && !this.customerSwitchOffRounding
    },
    case() {
      return this.availabilities?.find((a) => a?.plant === this.chosenWarehouse)
    },
    isInfoUser() {
      return (
        this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE) &&
        this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
      )
    },
    isStockInfoUser() {
      return (
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE) &&
        this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
      )
    },
    isQuoterUser() {
      return (
        this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE) &&
        this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY) &&
        this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
      )
    },
    excludedBrandName() {
      const brandOfProduct = this.product?.brandName
      return typeof brandOfProduct === 'string'
        ? this.excludedBrands.some((s) => brandOfProduct.toLowerCase().startsWith(s.toLowerCase()))
          ? brandOfProduct
          : null
        : null
    },
    hasShowAlternativePlants() {
      const hasShowAlternativePlants = this.configurations?.find((config) => config.key === CONFIGURATION_KEYS.SHOW_ALTERNATIVE_PLANTS_KEY)
      return hasShowAlternativePlants?.value === 'True'
    },
    nonDisplayOnlyAltPlants() {
      return this.availabilities?.filter((availability) => !this.isDisplayOnlyWareHouse(availability.plant)) ?? []
    },
    currencySymbol() {
      return getCurrencySymbol(this.$i18n.locale.toLowerCase(), this.cartInfos.currency)
    },
    packagingUnit() {
      return this.product?.packagingUnit && !this.customerSwitchOffRounding ? this.product?.packagingUnit : 1
    },
    minimumOrderQuantity() {
      const minimumOrderQuantity = this.product?.salesAreas?.[0]?.minimumOrderQuantity ?? 1
      return minimumOrderQuantity === 0 || minimumOrderQuantity === null ? this.packagingUnit : minimumOrderQuantity
    },
    hasExpectedPrice() {
      return (this.localCartItemValue?.expectedPricePer100 ?? null) !== null
    },
    isMvpActive() {
      return (this.price?.priceForRequestedQuantity + this.certificatePrice <= this.materialPrice?.minimumOrderLineValue && this.result?.priceForRequestedQuantity !== null)
    },
    isActive() {
      return !this.product || (this.product?.requestable ?? false) || (this.product?.buyable ?? false)
    },
    isBuyable() {
      return this.product?.buyable
    },
    isRecommendedPriceVisible() {
      const showRecommendedSetting = getSettingByKey(this.currentCustomerSettings, CUSTOMER_SETTING_KEYS.IsRecommSalesPriceVisible)
      return showRecommendedSetting?.customerSettingValue === '1' ?? false
    },
    price() {
      if (this.result && this.result !== null) {
        return this.result
      }
      return null
    },
    existingPrices() {
      const price = this.price
      return !isNaN(price?.priceForRequestedQuantity) || !isNaN(price?.pricePerPiece) || !isNaN(price?.pricePer100)
    },
    status() {
      return this.product?.salesAreas?.[0]?.status ?? this.product?.status
    },
    isDiscontinued() {
      return this.status === DISCONTINUED_STATUS
    },
    isDiscontinuedAndStockZero() {
      // eslint-disable-next-line eqeqeq
      return this.isDiscontinued && (this.case?.currentStock?.quantity === 0 || this.case?.currentStock?.quantity == null)
    },
    isLimitedAvailability() {
      return this.status === LIMITED_AVAILABILITY_STATUS
    },
    isLimitedAvailabilityAndStockZero() {
      // eslint-disable-next-line eqeqeq
      return this.isLimitedAvailability && (this.case?.currentStock?.quantity === 0 || this.case?.currentStock?.quantity == null)
    },
    isRequestOnly() {
      return REQUEST_ONLY_STATUS.includes(this.status) || this.isDiscontinuedAndStockZero
    },
    unit() {
      return this.materialPrice?.unit ?? this.product?.variant?.unit
    },
    keyPrice100() {
      if (this.unit === PRODUCT_UNITS.MTR || this.unit === PRODUCT_UNITS.M) {
        return 'pricePer100M'
      } else if (this.unit === PRODUCT_UNITS.PCE || this.unit === PRODUCT_UNITS.ST) {
        return 'pricePer100Pcs'
      } else if (this.unit === PRODUCT_UNITS.M2) {
        return 'pricePer100M2'
      }
      return 'pricePer100KG'
    },
    keyPrice1() {
      if (this.unit === PRODUCT_UNITS.MTR || this.unit === PRODUCT_UNITS.M) {
        return 'pricePerM'
      } else if (this.unit === PRODUCT_UNITS.PCE || this.unit === PRODUCT_UNITS.PCS || this.unit === PRODUCT_UNITS.ST) {
        return 'pricePerPiece'
      } else if (this.unit === PRODUCT_UNITS.M2) {
        return 'pricePerM2'
      }
      return 'pricePerKG'
    },
    stateChanged() {
      return `${this.fetchedWarehouses}${this.$auth.loggedIn}${this.product?.buyable}${this.deliveryAddressUuid}${this.product?.requestable}${this.product?.defaultPlantCode}${this.newCustomer}`
    },
    customerSwitchOffRounding() {
      const setting = getSettingByKey(this.currentCustomerSettings, CUSTOMER_SETTING_KEYS.SwitchOffRounding)
      return setting?.customerSettingValue === '1' ? true : false
    },
    showWarningMessage() {
      return this.chosenQuantity > this.currentStock && this.isDiscontinued
    },
    showMaxOrderQuantityWarningMessage() {
      let maxQty = this.product?.plants[0]?.maximumOrderQuantity ?? 0
      return maxQty > 0 && this.chosenQuantity > this.product.plants[0].maximumOrderQuantity
    },
    showErrorMessage() {
      return this.notMultipliableByPackaging && this.chosenQuantity !== null && this.chosenQuantity !== '' && !this.customerSwitchOffRounding
    },
    showLimitedAvailabilityStockError() {
      return this.isLimitedAvailability && this.case?.currentStock?.quantity < this.chosenQuantity && this.case?.currentStock.quantity > 0
    },
    showDiscontinuedStockError() {
      return this.isDiscontinued && this.case?.currentStock?.quantity < this.chosenQuantity && this.case?.currentStock?.quantity > 0
    },
    showLimitedAvailabilityNoStockError() {
      // eslint-disable-next-line eqeqeq
      return this.isLimitedAvailability && (this.case?.currentStock?.quantity === 0 || this.case?.currentStock?.quantity == null)
    },
    showInfoBox() {
      return !this.isRequestOnly && !(this.isDiscontinued && this.chosenQuantity > this.case?.currentStock?.quantity)
    },
    userDateFormat() {
      return this.settingValues?.User?.DateFormat?.substring(0, 10) ?? 'dd/MM/yyyy'
    },
    displayBuyButton() {
      return !((this.isDiscontinued && this.case?.currentStock?.quantity === 0) || (this.isLimitedAvailability && this.case?.currentStock?.quantity === 0))
    },
    isCatalogProduct() {
      return this.product?.catalogFlag ?? false
    },
    certificates() {
      let plantCertificates = this.availableCertificates?.filter(c => (c.onlyCatalog === this.isCatalogProduct || !c.onlyCatalog) && c.plant === this.plant?.plant)
      let defaultCertificates = this.availableCertificates?.filter(c => (c.onlyCatalog === this.isCatalogProduct || !c.onlyCatalog) && !plantCertificates.some(pc => pc.certificateTranslationKey === c.certificateTranslationKey) && c.plant === null)
      let certificates = plantCertificates.concat(defaultCertificates)
      const sorted = certificates?.sort((a, b) =>
        (this.$tv(a.certificateTranslationKey, 'certificates') || '').localeCompare(this.$tv(b.certificateTranslationKey, 'certificates') || '')
      )
      const data = [{certificateTranslationKey: 'noCertificate'}, ...sorted].map( item => ({
        text: this.$tv(item?.certificateTranslationKey, 'certificates'),
        value: item?.certificateUuid
      }))
      return data
    },
    isMarkedAsRequest () {
      return this.cartItemValue?.markedAsRequest
    },
    numberFormat () {
      return {
        style: 'currency',
        currency: this.cartInfos?.currency,
        currencyDisplay: 'symbol'
      }
    },
    isExpectedPriceEnabled () {
      const expectedPriceSetting = getSettingByKey(this.currentCustomerSettings, CUSTOMER_SETTING_KEYS.ExpectedPriceEnabled)
      return expectedPriceSetting?.customerSettingValue === '1' ?? false
    },
    isEndCustomerPriceEnabled () {
      const proformaInvoiceSetting = getSettingByKey(this.currentCustomerSettings, CUSTOMER_SETTING_KEYS.ProformaInvoice)
      return proformaInvoiceSetting?.customerSettingValue === '1' ?? false
    }
  },
  watch: {
    stateChanged: {
      async handler() {
        this.inProgress = true
        if (this.changeStateTimeoutHandle) {
          clearTimeout(this.changeStateTimeoutHandle)
        }
        this.changeStateTimeoutHandle = setTimeout(() => {
          this.loadData()
        }, 300)
      }
    },
    chosenWarehouse: {
      handler() {
        this.$eventbus.emit('emit-warehouse-change', this.chosenWarehouse)
      }
    },
    cartItemValue: {
      handler(newVal) {
        this.result = null
        this.priceScales = []
        this.localCartItemValue = {...newVal}
        this.updateValues()
        if (this.isActive && this.product) {
          this.loadData()
        }
      },
      deep: true,
      immediate: true
    },
    isEditCart() {
      this.updateValues()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/globals/colors';

.pdp-box {
  padding: 1rem;
  background-color: $color__white;
}

.pricing-bar .datepicker-wrapper {
  div {
    .k-datepicker {
      .k-dateinput {
        & + .k-button {
          width: auto;
          display: none;
        }
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.availability-pricing {
  background-color: $color__content--background__primary;
  position: relative;

  .edit-cart {
    display: none;
  }

  .round-up {
    margin-left: .5rem;
  }

  &__in-stock {
    padding: 0 0 1.5rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .light {
      font-size: $base-text-font-size;
      font-weight: lighter;
    }

    .stock-column {
      display: flex;
      flex-direction: column;

      .label-column {
        padding-right: 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 9rem;
        gap: .3125rem;
        font-size: map-get($base-text-label-medium, font-size );
        font-weight: map-get($base-text-label-medium, font-weight );

        .label-text-stockInfo,
        .label-text-stock {
          white-space: nowrap;
        }

        .only-remaining-stock {
          padding-left: .5rem;
        }

        .label-text-stockInfo {
          font-size: $base-text-font-size;
          font-weight: $base-text-font-weight-semi-bold;
          padding-bottom: .5rem;
          color: $color__primary;
        }

        span {
          font-weight: map-get($base-text-value-medium, font-weight );
        }
      }

      .content-column {
        width: 100%;
        font-size: $base-text-font-size;
        font-weight: $base-text-font-weight-semi-bold;

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
        }

        .no-gap {
          gap: 0;
        }

        .warehouse-row {
          display: flex;
          flex-direction: row;
          font-size: map-get($base-text-label-medium, font-size );
          font-weight: $base-text-font-weight-semi-bold;

          .future-line {
            padding-top: .25rem;
          }

          >label {
            align-self: center;
          }
        }

        .bottom-info {
          span {
            font-size: map-get($base-text-label-small, font-size);
          }
        }
      }

      .display-only-warehouse {
        display: flex;
        font-weight: $base-text-font-weight-light;
        flex-direction: column;
        gap:.2rem;
        font-size: map-get( $base-text-label-small, font-size);

        &__container {
          display: flex;
          align-items: center;
          gap: .5rem;
          font-style: italic;

        }

        h5 {
          margin:0;
        }

        .popup-content {
          padding-top: 1rem;
        }

        .icon.icon--svg {
          color: $color__primary;
          max-width: 1.2rem;
          min-width: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    &__left {
      width: calc(50% - .25rem);

      .quantity-label {
        font-size: map-get($base-text-label-medium, font-size );
        font-weight: map-get( $base-text-label-medium, font-weight );
      }

      @include breakpoint-down($sm) {
        width: 100%;
      }
    }

    &__right {
      width: calc(50% - .25rem);

      .availability-label {
        font-size: map-get($base-text-label-medium , font-size );
        font-weight: map-get($base-text-label-medium , font-weight );
      }

      @include breakpoint-down($sm) {
        width: 100%;
      }
    }

    .label-input {
      margin-top: .5rem;
    }

    .availability-pricing__packaging {
      margin-top: .5rem;

      span {
        display: inline-block;
        width: 50%;
        font-size: map-get($base-text-label-small , font-size );
        font-weight: map-get($base-text-label-small , font-weight);
      }
    }

    .date-picker-wrapper {
      border: .0625rem solid $color--input__border;

      .k-input-inner {
        border-top: none;
        border-right: .0625rem solid $color--input__border;
        border-bottom: none;
        border-left: none;
      }

      .k-button.k-button-md.k-icon-button.k-button-solid.k-button-solid-base.k-input-button {
        margin-left: 0;
      }
    }

    @include breakpoint-down($sm) {
      display: flex;
      flex-direction: column;
    }
  }

  &__quantity {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .enter-quantity {
      width: 8rem;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      span {
        margin-bottom: .7rem;
        font-weight: $base-text-font-weight-bold;
      }
    }

    .quantity-input-section {
      margin: 0;
      width: 100%;

      .min-multi-container {
        margin-left: 9.2rem;
        font-size: .8rem;

        &__multiple {
          margin-left: 1rem;
        }
      }

      .request-price {
        .expPrice {
          width: 5rem;
          height: 1.5rem;
          margin-left: 2.5rem;
          border: .1rem solid $color__black;
        }

        .expPriceCurrency {
          width: 2rem;
          height: 1.5rem;
          border: .1rem solid $color__black;
          margin-right: .5rem;
          background-color: $color__page--background--opacity;
          color: $color__black;
          margin-left: -.25rem;
        }

        .expPriceQty {
          width: 3rem;
          height: 1.5rem;
          margin-left: .5rem;
          border: .1rem solid $color__black;
        }

        .expPriceQtyUnit {
          width: 2rem;
          height: 1.5rem;
          border: .1rem solid $color__black;
          background-color: $color__page--background--opacity;
          color: $color__black;
          margin-left: -.25rem;
        }
      }

      .quantitfy-field {
        display: flex;
        flex-direction: column;

        .errors-and-warnings {
          margin-left: 9.2rem;
          margin-top: .3rem;

          &__datepicker-area {
            display: flex;
            align-items: center;
            font-size: $base-text-font-size;
            color: $color__primary;
            margin-left: .5rem;

            label {
              width: 7rem;
              margin: 0 .2rem;
            }

            .quantity-multipliable-error {
              margin-top: .15rem;
              font-size: .8rem;
              display: flex;
              align-items: center;

              .error-icon-container {
                width: 1.1;
                display: flex;

                span {
                  margin-left: .2rem;
                  margin-top: .01rem;
                }
              }
            }

            .lower-errorMsg-div-case4 {
              margin-top: .2rem;
            }

            .above-stock {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-top: .3rem;

              &__icon {
                margin-right: .1rem;
                margin-bottom: .09rem;
              }
            }
          }

          &__message-container {
            div {
              color: $color__text--error;
              font-size: .8rem;
              padding-left: .12rem;
            }
          }
        }
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;

    .btn-as-link {
      background-color: transparent;
      color: $color__fst__primary__hover;
      font-weight: $base-text-font-weight-light;

      &.k-button:hover.k-button-solid-base {
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }

  .messageBox_Container {
    .info-messages,
    .quantity-multipliable-error {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    }
  }

  &__quantity-case4 {
    border-bottom: none;
  }
}

.price-calc-container {
  padding: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  &__items {
    display: flex;
    flex-direction: column;
    gap: .625rem;
    flex: 0 50%;
    font-size: map-get($base-text-label-medium , font-size);
    font-weight: map-get($base-text-label-medium , font-weight);

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .minPosVal {
        flex-direction: column;
        align-items: flex-end;
      }

      .total-price-label {
        font-size: map-get($base-text-label-large, font-size );
        font-weight: map-get($base-text-label-large, font-weight );
        padding-top: .3125rem;
      }

      .total-price-element {
        text-align: right;
        font-size: map-get($base-text-value-large, font-size );
        font-weight: map-get($base-text-value-large, font-weight );
      }

      .mpv-label {
        font-size: map-get($base-text-label-small, font-size );
      }

      span {
        font-size: $base-text-font-size;
      }
    }

    &__certificate {
      white-space: nowrap;
    }
  }
}

.price-skeleton {
  height: 1rem;
  width: 3rem;

  &-big {
    width: 6rem;
    height: 1.725rem;
  }
}

.message-container {
  flex: 0 50%;
  display: flex;
  flex-direction: column;

  .exceptional-case,
  .message-mpv-case,
  .message-maxstock-case {
    padding: .5rem;
    border: .0625rem solid $color_warning_border;
    font-weight: $base-text-font-weight-light;
    font-size: $base-text-font-size;
  }

  .message-mpv-case {
    margin-top: auto;
  }
}

[id='newProductBuyBox'] {
  @include breakpoint-down($md) {
    &:not(.buy-box-in-popup) {
      position: relative;
    }
  }

  .buy-box--inactive .buy-box__section {
    filter: blur(.25rem);
  }

  .buy-box-mask {
    position: absolute;
    inset: 0;
    display: none;
    justify-content: center;
    align-items: center;

    &__layer {
      position: absolute;
      inset: 0;
      opacity: .75;
      background-color: $color__white;
      z-index: 3;
    }

    &__text {
      color: $color__primary;
      font-size: map-get($base-text-value-large, font-size );
      text-align: center;
      z-index: 3;
      padding: 1rem;
      cursor: default;

      #disSearch {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .loading-indicator {
      display: none;
    }

    &.loading-mask {
      .buy-box-mask__layer {
        background-color: $color__white;
      }
    }
  }

  .buy-box--inactive .buy-box-mask,
  .buy-box--loading .buy-box-mask {
    display: flex;
  }

  .buy-box--inactive .blur-buy-box,
  .buy-box--loading .blur-buy-box {
    filter: blur(.25rem);
  }

  .buy-box--loading .buy-box-mask .buy-box-mask__text {
    display: none;
  }

  .buy-box--loading .buy-box-mask .loading-indicator {
    z-index: 2;
    display: block;
  }

  @include breakpoint-down($sm) {
    .btn-container {
      display: flex;
      justify-content: space-around;
      gap: .2rem;
    }
  }

  .availability-pricing__edit-cart {
    &__input-fields {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-top: 1rem;

      &__position-note-expected-price, &__proforma-certificate {
        width: 100%;
        display: flex;
        gap: .5rem;
        padding-top: 1rem;

        .label-input {
          margin-top: .5rem;

          @include breakpoint-down($sm) {
            max-width: 100%;
          }
        }

        .proforma {
          display: flex;
          width: calc(50% - .25rem);

          @include breakpoint-down($sm) {
            width: 100%;
          }
        }

        .proforma-with-certificate {
          width: 100%;
        }

        .dropdown-certificate {
          max-width: 16.9rem;

          @include breakpoint-down($sm) {
            max-width: 100%;
          }

          @include breakpoint-between($md, $lg) {
            max-width: 14.5rem;
          }
        }

        div {
          flex-grow: 1;
          flex-basis: 50%;
        }

        label {
          font-size: $base-text-font-size;
          font-weight: $base-text-font-weight-light;
        }

        @include breakpoint-down($sm) {
          flex-direction: column;

          div {
            flex-basis: 100%;
          }
        }
      }
    }

  }
}
</style>
